<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/bed/UpdateBed"
      finish-router="bed"
      permission="Bed_Edit"
    >
      <template #facility="scope">
        <SlotFacility :row-data="scope.rowData" :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit" />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID" />
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID" />
      </template>

      <template #bed_type="scope">
        <SlotBedType :row-data="scope.rowData" :facility-id="scope.rowData.facilityID" />
      </template>

    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/bed/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import common from "@/common";
import PageContent from "@/components/PageContent";
import SlotFacility from "@/views/bed/SlotFacility";
import SlotFloor from "@/views/bed/SlotFloor";
import SlotRoom from "@/views/bed/SlotRoom";
import SlotBedType from "@/views/bed/SlotBedType";

export default {
  name: "Edit",
  components: {
    SlotBedType,
    SlotRoom,
    SlotFloor,
    SlotFacility,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('bed') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  created() {
    reloadFieldData()
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/bed/GetBed?bedID='+this.id).then(res => {
      this.row_data = res.data.bed
    })
  }
}
</script>

<style scoped>

</style>
